import { IRootState } from '@core/store/root.state';
import { createSelector } from '@ngrx/store';
import { ITillState } from '@core/store/tills/tills.state';

const _selectTills = (state: IRootState) => state.tills;

export const selectTillsIsLoading = createSelector(_selectTills, (state: ITillState) => state.isLoading);
export const selectTills = createSelector(_selectTills, (state: ITillState) => state.tills);
export const selectTillSessions = createSelector(_selectTills, (state: ITillState) => state.tillSessions);
export const selectLastTillSession = createSelector(_selectTills, (state: ITillState) => state.lastTillSession);
export const selectExpectedClosingAmounts = createSelector(_selectTills, (state: ITillState) => state.expectedClosingAmounts);
export const selectSelectedTillRange = createSelector(_selectTills, (state: ITillState) => state.selectedTillRange);
