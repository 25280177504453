import { Action } from '@ngrx/store';
import { ICreatePaymentIntentRequestModel } from '@models/request/create-payment-intent.request.model';
import { IPaymentIntentModel } from '@models/payment-intent.model';
import { PaginationHolder } from '@models/pagination.model';
import { RefundIntents } from '@app/modules/portal/pages/economy/pages/sales/components/refund-dialog/refund-dialog.component';

export enum EPaymentActions {
  CreatePaymentIntent = '[PAYMENT] Create Payment Intent',
  CreatePaymentIntentSuccess = '[PAYMENT] Create Payment Intent Success',

  FetchPaymentIntents = '[PAYMENT] Fetch Payment Intents',
  FetchPaymentIntentsSuccess = '[PAYMENT] Fetch Payment Intents Success',

  SendCustomerReceipt = '[PAYMENT] Send Customer Receipt',
  SendCustomerReceiptSuccess = '[PAYMENT] Send Customer Receipt Success',

  RefundPayment = '[PAYMENT] Refund Payment',
  RefundPaymentSuccess = '[PAYMENT] Refund Payment Success',

  CreateTerminalTransaction = '[PAYMENT] Create Terminal Transaction',
  CreateTerminalTransactionSuccess = '[PAYMENT] Create Terminal Transaction Success',

  CancelTerminalTransaction = '[PAYMENT] Cancel Terminal Transaction',
  CancelTerminalTransactionSuccess = '[PAYMENT] Cancel Terminal Transaction Success',
}

// Create Payment Intent

export class CreatePaymentIntent implements Action {
  public readonly type = EPaymentActions.CreatePaymentIntent;

  constructor(
    public order_id: string,
    public reqModel: ICreatePaymentIntentRequestModel,
    public receiptEmail?: string
  ) {}
}

export class CreatePaymentIntentSuccess implements Action {
  public readonly type = EPaymentActions.CreatePaymentIntentSuccess;

  constructor() {}
}

// Fetch Payment Intents

export class FetchPaymentIntents implements Action {
  public readonly type = EPaymentActions.FetchPaymentIntents;

  constructor(public order_id: string) {}
}

export class FetchPaymentIntentsSuccess implements Action {
  public readonly type = EPaymentActions.FetchPaymentIntentsSuccess;

  constructor(public intents: PaginationHolder<IPaymentIntentModel>) {}
}

// Send Customer Receipt

export class SendCustomerReceipt implements Action {
  public readonly type = EPaymentActions.SendCustomerReceipt;

  constructor(
    public order_id: string,
    public receiptEmail?: string
  ) {}
}

export class SendCustomerReceiptSuccess implements Action {
  public readonly type = EPaymentActions.SendCustomerReceiptSuccess;

  constructor() {}
}

// Refund Payment

export class RefundPayment implements Action {
  public readonly type = EPaymentActions.RefundPayment;

  constructor(
    public id: string,
    public intents: RefundIntents[]
  ) {}
}

export class RefundPaymentSuccess implements Action {
  public readonly type = EPaymentActions.RefundPaymentSuccess;

  constructor() {}
}

// Create Terminal Transaction

export class CreateTerminalTransaction implements Action {
  public readonly type = EPaymentActions.CreateTerminalTransaction;

  constructor(
    public payment_intent_id: string,
    public amount: number,
    public terminal_id: string
  ) {}
}

export class CreateTerminalTransactionSuccess implements Action {
  public readonly type = EPaymentActions.CreateTerminalTransactionSuccess;

  constructor() {}
}

// Cancel Terminal Transaction

export class CancelTerminalTransaction implements Action {
  public readonly type = EPaymentActions.CancelTerminalTransaction;

  constructor(
    public terminal_id: string,
    public transaction_id: string
  ) {}
}

export class CancelTerminalTransactionSuccess implements Action {
  public readonly type = EPaymentActions.CancelTerminalTransactionSuccess;

  constructor() {}
}

export type PaymentActions =
  | CreatePaymentIntent
  | CreatePaymentIntentSuccess
  | FetchPaymentIntents
  | FetchPaymentIntentsSuccess
  | SendCustomerReceipt
  | SendCustomerReceiptSuccess
  | RefundPayment
  | RefundPaymentSuccess
  | CreateTerminalTransaction
  | CreateTerminalTransactionSuccess
  | CancelTerminalTransaction
  | CancelTerminalTransactionSuccess;
